export default {
  "general-info": {
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "current-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Residence"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria/Vienna"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "native-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native Language"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "other-languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Languages"])},
    "english-italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English very good, Italian improvable"])},
    "driving-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving license"])},
    "class-b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class B"])},
    "covid-vaccination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covid-19 Vaccination"])},
    "three-shots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three Shots"])}
  },
  "education": {
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSc Media Informatics and Visual Computing - TU Vienna"])},
    "high-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Secondary School with specialization in Computer Science"])},
    "middle-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle School in Sand in Taufers"])}
  },
  "work-experience": {
    "work-experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
    "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
    "summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summer"])},
    "metgis": {
      "serverimplementation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server implementation (Rust/Java/Node.js/Python)"])},
      "apis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation of REST and gRPC APIs"])},
      "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realization of Websites with Vue.js/HTML/CSS/JavaScript"])},
      "geospatial-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing and creation of geospatial data"])},
      "docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of Dockerfile/Compose files; Deploying on Servers"])},
      "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow Automation"])},
      "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PostgreSQL/PostGIS, MongoDB"])}
    },
    "elektrisola": {
      "java": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java: Server/Client Application with REST"])},
      "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue.js/HTML/CSS/JavaScript"])},
      "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL, IBM AS400"])},
      "server-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sever Maintenance/Docker"])},
      "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation using Java Auto Tasks and Cron Jobs"])}
    },
    "dengg": {
      "engineer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engineer"])},
      "acad-civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACAD-Civil: Terrain modeling for power plant projects in Ukraine"])},
      "autocad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoCAD"])}
    },
    "contech": {
      "internet-agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Agency"])},
      "website-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website maintenance"])},
      "photoshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adobe Photoshop"])}
    },
    "kerer": {
      "network-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network maintenance"])},
      "server-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server maintenance"])}
    }
  },
  "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Technologies"])},
  "hire-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire Me!"])},
  "i-am-currently-in-paternity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am currently in paternity. I am available from 2022-04-25."])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
}