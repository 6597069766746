export default {
  "general-info": {
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatsangehörigkeit"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
    "current-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Wohnsitz"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Österreich/Wien"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alter"])},
    "native-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muttersprache"])},
    "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "other-languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Sprachen"])},
    "english-italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch sehr gut, Italienisch ausbaufähig"])},
    "driving-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein"])},
    "class-b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasse B"])},
    "covid-vaccination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covid-19 Impfung"])},
    "three-shots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drei Dosen"])}
  },
  "education": {
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung"])},
    "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BSc Medieninformatik und Visual Computing - TU Vienna"])},
    "high-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologische Fachoberschule mit Fachrichtung Informatik"])},
    "middle-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittelschule in Sand in Taufers"])}
  },
  "work-experience": {
    "work-experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitserfahrung"])},
    "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktikum"])},
    "summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommer"])},
    "metgis": {
      "serverimplementation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Implementierung (Rust/Java/Node.js/Python)"])},
      "apis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementierung von REST und gRPC APIs"])},
      "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierung von Webseiten mit Vue.js/HTML/CSS/JavaScript"])},
      "geospatial-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung und Erstellung von georäumlichen Daten"])},
      "docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellung von Dockerfile/Compose Dateien; Deployen auf Servern"])},
      "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow Automatisierung"])},
      "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PostgreSQL/PostGIS, MongoDB"])}
    },
    "elektrisola": {
      "java": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java: Server/Client Programm mit REST"])},
      "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue.js/HTML/CSS/JavaScript"])},
      "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL, IBM AS400"])},
      "server-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sever Wartung/Docker"])},
      "automation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisierung mit Java Auto Tasks und Cron Jobs"])}
    },
    "dengg": {
      "engineer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingenieur"])},
      "acad-civil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACAD-Civil: Geländemodellierung für Kraftwerksprojekte in der Ukraine"])},
      "autocad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AutoCAD"])}
    },
    "contech": {
      "internet-agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet Agentur"])},
      "website-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website Wartung"])},
      "photoshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adobe Photoshop"])}
    },
    "kerer": {
      "network-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk Wartung"])},
      "server-maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Wartung"])}
    }
  },
  "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Technologien"])},
  "hire-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie mich ein!"])},
  "i-am-currently-in-paternity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich befinde mich derzeit in Karenz. Ich bin ab 2022-04-25 verfügbar."])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
}